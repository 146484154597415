// caricamento lingue tooltip
import datepickerIt from 'init/bootstrap-datepicker/datepicker.it'

$.extend($.fn.datepicker.defaults, {
  format: 'dd-mm-yyyy',
  weekStart: 1,
  language: !localStorage.getItem('locale') ? 'it' : localStorage.getItem('locale'),
  orientation: 'bottom',
  todayHighlight: true,
  todayBtn: 'linked',
  clearBtn: true,
})

// impostazione traduzioni
$.fn.datepicker.dates.it = datepickerIt

$(document).on('ready turbolinks:load', function() {
  // datepicker
  $('.datepicker').datepicker()
  // datepicker con autoclose
  $('.datepicker-autoclose').datepicker({
    autoclose: true,
    orientation: 'bottom'
  })
  // datepicker esteso
  $('.datepicker-inline').datepicker()
  // datepicker multi data
  $('.datepicker-multiple-date').datepicker({
    multidate: true,
    multidateSeparator: ","
  })
  // datepicker range
  $('.date-range').datepicker({
    toggleActive: true
  })
})
