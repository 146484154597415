$(document).on('ready turbolinks:load', function() {
  // toggle della password
  $('.togglePassword').on('click', function() {
    icon_svg = $(this).find('svg')
    if (icon_svg.attr('data-icon') === 'eye') {
      icon_svg.attr('data-icon', 'eye-slash')
    } else {
      icon_svg.attr('data-icon', 'eye')
    }

    input = $($(this).data('toggle'))
    if (input.attr('type') == 'password') {
      input.attr('type', 'text')
    } else {
      input.attr('type', 'password')
    }
  });

  // Set LocalStorage locale: it
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'it');
    lang = 'it';
  } else {
    lang = localStorage.getItem('locale');
  }

  
  tippy(
    '.tippy-btn',
    {
      theme: 'light',
      duration: 0,
      arrow: true,
      // allowHTML: true,
      delay: [0, 100],
    }
  );
  // tippy('#myElement', {
  //   html: document.querySelector('#feature__html'), // DIRECT ELEMENT option
  //   arrow: true,
  //   animation: 'fade'
  // });


  function initMetisMenu() {
    //metis menu
    $("#side-menu").metisMenu();
  }

  function initLeftMenuCollapse() {
    $('#vertical-menu-btn').on('click', function (event) {
      event.preventDefault();
      $('body').toggleClass('sidebar-enable');
      if ($(window).width() >= 992) {
        $('body').toggleClass('vertical-collpsed');
      } else {
        $('body').removeClass('vertical-collpsed');
      }
    });
  }

  function initActiveMenu() {
    // === following js will activate the menu in left side bar based on url ====
    $("#sidebar-menu a").each(function () {
      var pageUrl = window.location.href.split(/[?#]/)[0];
      if (this.href == pageUrl) {
        $(this).addClass("active");
        $(this).parent().addClass("mm-active"); // add active to li of the current link
        $(this).parent().parent().addClass("mm-show");
        $(this).parent().parent().prev().addClass("mm-active"); // add active class to an anchor
        $(this).parent().parent().parent().addClass("mm-active");
        $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
        $(this).parent().parent().parent().parent().parent().addClass("mm-active");
      }
    });
}

  function initMenuItemScroll() {
    // focus active menu in left sidebar
    $(document).ready(function(){
      if($("#sidebar-menu").length > 0 && $("#sidebar-menu .mm-active .active").length > 0){
        var activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
        if( activeMenu > 300) {
          activeMenu = activeMenu - 300;
          $(".vertical-menu .simplebar-content-wrapper").animate({ scrollTop: activeMenu }, "slow");
        }
      }
    });
  }

  function initFullScreen() {
    $('[data-toggle="fullscreen"]').on("click", function (e) {
        e.preventDefault();
        $('body').toggleClass('fullscreen-enable');
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    });
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    function exitHandler() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            console.log('pressed');
            $('body').removeClass('fullscreen-enable');
        }
    }
  }

  function initsearch() {
    $('.toggle-search').on('click', function () {
      var targetId = $(this).data('target');
      var $searchBar;
      if (targetId) {
        $searchBar = $(targetId);
        $searchBar.toggleClass('open');
      }
    });
  }

  function initCartAddSubmit() {
    $(".cart-inline-form button").on("click", function() {
      $(this).prop("disabled", true);
    });
  }

  function cartUpdateObserver() {
    $('.cart-inline-form #cart_item_quantity').on('change', function() {
      var submitButton = $(this).parent().children().last();
      var submitIcon = $(this).parent().find('i.mdi-refresh');
      submitButton.addClass('border-warning');
      submitIcon.addClass('text-warning');

      // console.log('submit: ', submitButton);
      // console.log('icon: ', submitIcon);
  
    });
  }

  function initFormBootstrapValidation () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  function initInputmask() {
    Inputmask.extendAliases({
      euro: {
        prefix: "€ ",
        groupSeparator: ".",
        radixPoint: ",",
        alias: "numeric",
        placeholder: "0",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
        autoUnmask: true,
        unmaskAsNumber: true
      }
    });
  }

  function init() {
    initMetisMenu();
    initLeftMenuCollapse();
    initActiveMenu();
    initMenuItemScroll();
    initFullScreen();
    // initMetisMenu();
    initsearch();
    initFormBootstrapValidation();
    initInputmask();
    initCartAddSubmit();
    cartUpdateObserver();
  }

  init();

  
})