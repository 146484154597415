// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
global.$ = global.jQuery = jQuery;

// immagini
const images = require.context('images', true)
// const imagePath = (name) => images(name, true)

// Bootstrap
// Import the specific modules you may need (Modal, Alert, etc)
import { Modal, Alert, Tooltip, Popover } from 'bootstrap';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

// Sweet Alert 2
import '../src/sweet-alert';

// DropZone.JS
import { Dropzone } from 'dropzone';
global.Dropzone = Dropzone;

import 'stylesheets/application';

$(document).on('ready turbolinks:load', function() {
  // Tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
  // Popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  });
})

// https://atomiks.github.io/tippyjs/
import tippy from 'tippy.js';
global.tippy = tippy;

import 'materialize-css/dist/js/materialize';

// Datatables
require( 'jszip' );
// require( 'pdfmake' );
require( 'datatables.net-bs5' );
require( 'datatables.net-buttons-bs5' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-datetime' );
require( 'datatables.net-fixedcolumns-bs5' );
require( 'datatables.net-fixedheader-bs5' );
// require( 'datatables.net-responsive-bs5' );
require( 'datatables.net-scroller-bs5' );
require( 'datatables.net-searchbuilder-bs5' );
require( 'datatables.net-select-bs5' );



// // Tempus Dominus datetime picker
// require('moment');
// require('moment/locale/it');
// require('tempusdominus-bootstrap')

// Bootstrap date picker
require('bootstrap-datepicker');

// Collapsible jQuery menu plugin
// https://yarnpkg.com/package/metismenu 
require('metismenu');

// // inizializzatori javascript
require('init/datatables')
// require('init/tempusdominus')
require('init/bootstrap-datepicker')

// // Swiper
// import Swiper from 'swiper'
// window.Swiper = Swiper

// Inputmask
require('inputmask');

// javascript personalizzato
require('../src/custom')
